import classNames from "classnames";
import React from "react";
import { toCapital } from "../utils";

const Label = ({ label, className }) => {
  let theme;

  switch (label) {
    case "postponed":
      theme = "bg-yellow-300";
      break;

    case "canceled":
      theme = "bg-red-400";
      break;

    case "promotion":
      theme = "bg-pink-500";
      break;

    case "rescheduled":
      theme = "bg-yellow-500";
      break;

    default:
      theme = "bg-vip-light-gray";
      break;
  }

  return (
    <span
      className={classNames(
        className,
        "text-sm font-semibold px-3 py-1 rounded-full",
        theme
      )}
    >
      {toCapital(label)}
    </span>
  );
};

export default Label;
