import moment from "moment";

export const DATE_FORMAT = "YYYY-M-D";

export const currency = (number) => {
  // if (typeof parseFloat(number) === "NaN") return number;
  return parseFloat(number).toFixed(2);
};

export const sha1 = (value) => {
  return value;
};

export const toKabobCase = (str) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join("-");

export const toCapital = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export function addEventContextAndSort(events) {
  var withContext = [...events];

  withContext
    .sort(
      (a, b) =>
        moment(a.start_date, DATE_FORMAT) - moment(b.start_date, DATE_FORMAT)
    )
    .forEach((event) => {
      event.isUpcoming =
        event.start_date &&
        moment(event.start_date, DATE_FORMAT).isSameOrAfter(moment(), "day");

      event.isCurrent =
        event.start_date &&
        event.end_date &&
        (moment(event.start_date, DATE_FORMAT).isSame(moment(), "day") ||
          moment().isBetween(
            moment(event.start_date, DATE_FORMAT),
            moment(event.end_date, DATE_FORMAT),
            "day"
          ) ||
          moment(event.end_date, DATE_FORMAT).isSame(moment(), "day"));

      event.isPast =
        event.start_date &&
        moment(event.start_date, DATE_FORMAT).isBefore(moment(), "day");
    });

  return withContext;
}

export const getUpcomingEvents = (events) => {
  return [...events].filter(({ isUpcoming }) => isUpcoming);
};

export const getUpcomingAndCurrentEvents = (events) => {
  return [...events].filter(
    ({ isUpcoming, isCurrent }) => isUpcoming || isCurrent
  );
};

export const getPastEvents = (events) => {
  return [...events].filter(({ isPast }) => isPast);
};
