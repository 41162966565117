import React from 'react';
import moment from 'moment';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import {
  DATE_FORMAT,
  addEventContextAndSort,
  getUpcomingAndCurrentEvents,
  getPastEvents,
  toKabobCase,
} from '../utils';
import Label from '../components/label';

const Events = ({ data }) => {
  const events = addEventContextAndSort(data.allEventsJson.events);
  const upcomingEvents = getUpcomingAndCurrentEvents(events);
  const pastEvents = getPastEvents(events).reverse();

  return (
    <Layout>
      <div className="mb-16">
        {upcomingEvents.map((event, index) => (
          <EventCard key={index} event={event} />
        ))}
      </div>

      <h2 className="text-center mb-16">Past Events</h2>
      <div>
        {pastEvents.map((event, index) => (
          <EventCard key={index} event={event} />
        ))}
      </div>
    </Layout>
  );
};

const EventCard = ({
  event: { title, labels, description, image, start_date, end_date },
}) => (
  <div
    id={toKabobCase(title) + '-' + start_date}
    className="flex flex-col md:flex-row bg-vip-pink border-2 mb-6 overflow-hidden rounded-xl"
  >
    <div
      className="overflow-hidden flex-grow"
      style={{
        maxWidth: 300,
        maxHeight: 300,
      }}
    >
      {image?.childImageSharp?.gatsbyImageData && (
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt={title}
        />
      )}
    </div>
    <div className="p-6">
      <span className="h2 block">{title}</span>
      <span className="block mb-3">
        {moment(start_date, DATE_FORMAT).format('M/D/YYYY')}
        {end_date
          ? ' - ' + moment(end_date, DATE_FORMAT).format('M/D/YYYY')
          : ''}
      </span>
      {labels && (
        <div className="mb-3">
          {labels?.map((label, index) => (
            <Label
              key={index}
              className={index !== labels.length ? 'mr-2' : ''}
              label={label}
            />
          ))}
        </div>
      )}
      <p>{description}</p>
    </div>
  </div>
);

export const query = graphql`
  query {
    allEventsJson {
      events: nodes {
        title
        labels
        description
        start_date
        end_date
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export default Events;
